import { useCallback, useEffect, useRef, useState } from 'react'
import { simulationFields } from 'queries'
import { IBody, IEmployee, IFactor, IQueryParams, ISimulationConfig } from 'interfaces'
import { get, orderBy, sumBy } from 'lodash'
import { calcSimulate, verifyLimits, isModality as CheckModality } from 'helpers'
import moment from 'moment'

import useApi from './useApi'
import useCore from './useCore'
import IInsurer from 'interfaces/insurer'
import PATHS from 'constants/paths'
import useLayout from './useLayout'

interface ICloneState {
  optar_por_seguro: boolean
  data_primeiro_vencimento: string
  valor_parcela?: number
  valor_solicitado?: number
}

interface IHooksSimulation {
  queryParams?: IQueryParams
  pathSimulation: string
  isRenegociate: boolean
  isParcel: boolean
  pathEmployeeInformation?: string
  informationsForm?: IBody
  enabledSecurity?: boolean
}

interface GetMinMaxParams {
  factor?: IFactor
  parcel?: boolean
}

export function useSimulationConfig({
  pathSimulation,
  queryParams,
  isRenegociate,
  isParcel = false,
  pathEmployeeInformation,
  informationsForm,
  enabledSecurity,
}: IHooksSimulation) {
  const { showSnackBar } = useLayout()

  // Objeto de configuração de dados necessários para simulação
  const [simulationConfig, setSimulationConfig] = useState<ISimulationConfig>()
  // Campos principais para cálculo
  const [selectedFactor, setSelectedFactor] = useState<IFactor>()
  const [valueRequest, setValueRequest] = useState<number>(0)
  const [totalValue, setTotalValue] = useState<number>(0)
  const [selectedFirstFactor, setSelectedFirstFactor] = useState<number>(0)
  const [valueRenegociateContracts, setValueRenegociateContracts] = useState<number>(0)
  // Habilita seguro
  const [enableInsurer, setEnableInsurer] = useState<boolean>(false)
  // Valores referente ao cálculo com seguro
  const [valueInsurer, setValueInsurer] = useState<number>(0)
  const [insurer, setInsurer] = useState<IInsurer>()
  const [isRefreshInformations, setIsRefreshInformations] = useState<boolean>(false)
  const [disableButtonState, setDisableButtonState] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [employee, setEmployee] = useState<IEmployee>()
  const { apiRequest } = useApi()
  const { entity } = useCore()
  const stateParamsRef = useRef<ICloneState>({} as ICloneState)
  const queryParamsRef = useRef<IQueryParams>(queryParams || {})
  const [formStateValues, setFormStateValues] = useState<ICloneState>({} as ICloneState)
  const [isDate, setIsDate] = useState<string>(moment().add(30, 'days').format('YYYY-MM-DD'))
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const isModality = CheckModality(entity)
  const isCDC = isModality === 'CDC'
  const isCDV = isModality === 'CDCVEICULO'
  const isCorban = isModality === 'CORBAN'
  const isCP = isModality === 'CP'

  const isCDCandRenegociate = isCDC && isRenegociate
  const isCDVandRenegotiate = isCDV && isRenegociate

  const handleChange = (value: number) => {
    setValueRequest(value)
  }

  const handleChangeParcela = (value: IFactor) => {
    setSelectedFactor(value)
  }

  const handleBlur = (value: number) => {
    setValueRequest(value)
    setFormStateValues((prevState) => ({
      ...prevState,
      ...(isParcel ? { valor_parcela: value } : { valor_solicitado: value }),
    }))
    setIsRefreshInformations(false)
  }

  const handleFocus = () => {
    setIsRefreshInformations(true)
  }

  // Pegar limite minimo
  const getMinValue = ({ factor, parcel }: GetMinMaxParams) => {
    const currentMode = parcel !== undefined ? parcel : isParcel
    if (selectedFactor) {
      const currentFactor = factor || selectedFactor

      if (simulationConfig && simulationConfig.limites) {
        if (currentMode) {
          if (currentFactor.parcela_minima >= simulationConfig.limites.financeira_valor_minimo) {
            return currentFactor.parcela_minima
          }
          if (currentFactor.parcela_minima > 0) {
            return (
              simulationConfig.limites.financeira_valor_minimo / (isCP ? 1 : currentFactor.fator)
            )
          }
          return 0
        }

        if (currentFactor.valor_minimo >= simulationConfig.limites.financeira_valor_minimo) {
          return currentFactor.valor_minimo
        }
        if (currentFactor.valor_minimo > 0) {
          return simulationConfig.limites.financeira_valor_minimo
        }
      }
      return currentFactor.valor_minimo
    }
    return 0
  }

  // Função para saber o valor máximo da financeira
  const isFinancialMaxValue = (factor?: IFactor) => {
    if (selectedFactor) {
      const currentFactor = factor || selectedFactor
      if (simulationConfig) {
        const maxValueSimulation = simulationConfig.limites?.financiamento
          ? simulationConfig.limites.financiamento
          : simulationConfig.limites.financeira_valor_maximo
        return maxValueSimulation < currentFactor.valor_maximo
      }
    }
    return false
  }

  // Limite máximo
  const getMaxValues = ({ factor, parcel }: GetMinMaxParams) => {
    const currentMode = parcel !== undefined ? parcel : isParcel
    if (selectedFactor) {
      const currentFactor = factor || selectedFactor
      const maxValue = currentMode ? currentFactor.parcela_maxima : currentFactor.valor_maximo
      if (isFinancialMaxValue(factor) && simulationConfig) {
        const maxValueSimulation = simulationConfig.limites?.financiamento
          ? simulationConfig.limites.financiamento
          : simulationConfig.limites.financeira_valor_maximo
        return currentMode ? maxValueSimulation / currentFactor.fator : maxValueSimulation
      }
      return maxValue
    }
    return 0
  }

  const initialValues = () => {
    if (selectedFactor) {
      if (isParcel) {
        if (isCP && !isRenegociate) postSimulationConfig(getMaxValues({}))
        return isRenegociate ? valueRenegociateContracts / selectedFactor.fator : getMaxValues({})
      }
      if (isCP && !isRenegociate) postSimulationConfig(getMaxValues({}))
      return isRenegociate ? valueRenegociateContracts : getMaxValues({})
    }
    return 0
  }

  const resetFields = () => {
    if (simulationConfig) {
      const lastElement = simulationConfig.fatores[simulationConfig.fatores.length - 1]

      setValueRequest(initialValues())
      setSelectedFactor(lastElement)
    }
  }

  // Bloqueio do input para solicitação e renegociação de crédito
  const isBlockedInput = () => {
    if (isCDCandRenegociate || isCDVandRenegotiate) {
      return true
    }

    if (simulationConfig && selectedFactor && simulationConfig.limites) {
      const minValueInvalid =
        selectedFactor.valor_minimo < simulationConfig.limites.financeira_valor_minimo
      const maxValueInvalid = getMaxValues({}) < selectedFactor.valor_minimo
      return minValueInvalid || maxValueInvalid
    }

    return false
  }

  const verificarDataFutura = (date: string) => {
    const dataFutura = moment(date)

    // Verificar se é fim de semana
    const isFimDeSemana = dataFutura.day() === 0 || dataFutura.day() === 6

    return {
      isFimDeSemana,
    }
  }

  const postSimulationConfig = useCallback(
    async (value?: number) => {
      const { isFimDeSemana } = verificarDataFutura(isDate)
      const responsePost = await apiRequest({
        path: PATHS.CP.SIMULATE_CREDIT_POST,
        method: 'POST',
        body: {
          optar_por_seguro: enabledSecurity,
          cpf: informationsForm?.cpf,
          ...(!isFimDeSemana ? { data_primeiro_vencimento: isDate } : {}),
          ...(isParcel
            ? { valor_parcela: value ? value : valueRequest }
            : { valor_solicitado: value ? value : valueRequest }),
        },
      })

      if (responsePost && !responsePost.data) {
        stateParamsRef.current = {
          optar_por_seguro: enabledSecurity!,
          data_primeiro_vencimento: isDate,
          ...(isParcel
            ? { valor_parcela: value ?? valueRequest }
            : { valor_solicitado: value ?? valueRequest }),
        }
        setFormStateValues({
          optar_por_seguro: enabledSecurity!,
          data_primeiro_vencimento: isDate,
          ...(isParcel
            ? { valor_parcela: value ?? valueRequest }
            : { valor_solicitado: value ?? valueRequest }),
        })
        const orderedFactors = orderBy(responsePost.fatores, 'prazo')
        const contractValues = responsePost.contratos
          ? sumBy(responsePost.contratos, 'saldo_devedor')
          : 0
        const isContainsInsurer = responsePost?.seguradora
        const factor: IFactor = get(
          orderedFactors,
          `[${orderedFactors ? orderedFactors?.length - 1 : 0}]`
        )

        if (contractValues) setValueRenegociateContracts(contractValues)
        setSimulationConfig({
          ...responsePost,
          fatores: orderedFactors,
          seguradora: isContainsInsurer,
        })

        if (factor) {
          setTotalValue(isParcel ? factor.valor_liberado : factor.recebivel)
          setSelectedFirstFactor(factor?.prazo)
          setSelectedFactor(factor)
        } else {
          setSelectedFactor({} as IFactor)
        }
      } else {
        showSnackBar({
          type: 'error',
          message:
            'Não é possível simular o empréstimo com os dados informados. Revise os valores.',
        })
      }
    },
    [apiRequest, isDate, valueRequest, isParcel, informationsForm, enabledSecurity]
  )

  const getSimulationConfig = useCallback(async () => {
    setIsLoading((prevState) => !prevState)
    const response = await apiRequest({
      path: pathSimulation,
      method: 'GET',
      queryParams: {
        ...queryParamsRef.current,
        fields: simulationFields,
      },
    })

    if (response && !response?.data) {
      const orderedFactors = orderBy(response.fatores, 'prazo')
      const contractValues = response.contratos ? sumBy(response.contratos, 'saldo_devedor') : 0
      const isContainsInsurer = response?.seguradora
      const factor: IFactor = get(
        orderedFactors,
        `[${orderedFactors ? orderedFactors?.length - 1 : 0}]`
      )

      if (isContainsInsurer) {
        setEnableInsurer(true)
        setInsurer(isContainsInsurer)
      }

      if (contractValues) setValueRenegociateContracts(contractValues)
      setSimulationConfig({
        ...response,
        fatores: orderedFactors,
        seguradora: isContainsInsurer,
      })
      if (factor) {
        setSelectedFirstFactor(factor?.prazo)
        setSelectedFactor(factor)
      } else {
        setSelectedFactor({} as IFactor)
      }
      setIsLoading((prevState) => !prevState)
      return
    } else {
      setError((prevState) => !prevState)
      setIsLoading((prevState) => !prevState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiRequest, setSimulationConfig, setSelectedFactor, pathSimulation])

  const getEmployees = useCallback(async () => {
    if (pathEmployeeInformation) {
      const response = await apiRequest({
        path: pathEmployeeInformation,
        method: 'GET',
        queryParams: {
          fields: 'nome,sobrenome,id,cpf,telefone_celular',
          search: queryParams?.funcionario_cpf?.replace(/[^\d]+/g, ''),
        },
      })

      if (response && response.results?.length) {
        setEmployee(response.results[0])
        return
      }
      setEmployee({} as IEmployee)
      return
    }
  }, [apiRequest, queryParams, pathEmployeeInformation])

  useEffect(() => {
    getSimulationConfig()
  }, [getSimulationConfig])

  useEffect(() => {
    if (isCorban) getEmployees()
  }, [getEmployees, isCorban])

  useEffect(() => {
    if (selectedFactor) {
      if (valueRequest === 0) {
        handleChange(initialValues())
      } else {
        const maxValue = getMaxValues({})
        const minValue = getMinValue({})
        handleChange(verifyLimits(valueRequest, maxValue, minValue))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFactor])

  useEffect(() => {
    handleChange(initialValues())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isParcel])

  useEffect(() => {
    if (selectedFactor) {
      const maxValue = getMaxValues({ parcel: false })
      const minValue = getMinValue({ parcel: false })
      const maxValueParcel = getMaxValues({ parcel: true })
      const minValueParcel = getMinValue({ parcel: true })
      const { newValue, newInsurerValue } = calcSimulate({
        values: valueRequest,
        factor: selectedFactor,
        maxValue,
        minValue,
        maxValueParcel,
        minValueParcel,
        isParcel: isParcel,
        insurer,
        isCP,
        isRenegociate,
        enabledSecurity,
      })

      setTotalValue(newValue)
      setValueInsurer(newInsurerValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueRequest, selectedFactor, isParcel, insurer, isCP, enabledSecurity])

  return {
    simulationConfig,
    selectedFactor,
    selectedFirstFactor,
    valueRenegociateContracts,
    valueInsurer,
    error,
    queryParamsRef,
    stateParamsRef,
    valueRequest,
    totalValue,
    isRefreshInformations,
    enableInsurer,
    employee,
    isDate,
    formStateValues,
    disableButtonState,
    isLoading,
    getMinValue,
    getMaxValues,
    initialValues,
    handleChange,
    handleChangeParcela,
    resetFields,
    isBlockedInput,
    setSelectedFactor,
    handleBlur,
    handleFocus,
    postSimulationConfig,
    setIsRefreshInformations,
    setIsDate,
    setFormStateValues,
    setDisableButtonState,
    setIsLoading,
  }
}

export default useSimulationConfig
