import LazyLoading from 'components/LazyLoading'
import {
  AttachMoney,
  Description,
  PermIdentityOutlined,
  PlaylistAddOutlined,
  Receipt,
  RestorePageOutlined,
  SupervisorAccount,
  SyncAlt,
} from '@material-ui/icons'
import { routeCodes, subRouteCodes } from 'constants/routes'
import { IRoute } from 'interfaces'
import hasSubmodulePermission from 'permissions/hasSubmodulePermission'
import { CORBAN_SUBMODULES } from 'interfaces/submodules'

const HireCredit = LazyLoading(() => import('./pages/HireCredit'))
const AssociateChannels = LazyLoading(() => import('./pages/AssociateChannel'))
const OperatorsLayout = LazyLoading(() => import('./pages/Operators'))
const OperatorsList = LazyLoading(() => import('./pages/Operators/List'))
const OperatorsCreate = LazyLoading(() => import('./pages/Operators/New'))
const OperatorsView = LazyLoading(() => import('./pages/Operators/View'))
const Simulate = LazyLoading(() => import('./pages/HireCredit/Simulate'))
const Resume = LazyLoading(() => import('./pages/HireCredit/Resume'))
const VerifyIdentity = LazyLoading(() => import('./pages/VerifyIdentity'))
const VerifyToken = LazyLoading(() => import('./pages/VerifyToken'))
const Redirect = LazyLoading(() => import('./pages/Redirect'))
const LGPD = LazyLoading(() => import('./pages/LGPD'))
const LGPDCompanySelect = LazyLoading(() => import('./pages/LGPD/CompanySelect'))
const LGPDForm = LazyLoading(() => import('./pages/LGPD/Form'))
const ContractsLayout = LazyLoading(() => import('./pages/Contracts'))
const ContractsList = LazyLoading(() => import('./pages/Contracts/List'))
const ContractsView = LazyLoading(() => import('./pages/Contracts/View'))
const ProposalsLayout = LazyLoading(() => import('./pages/Proposal'))
const ProposalsList = LazyLoading(() => import('./pages/Proposal/List'))
const ProposalsView = LazyLoading(() => import('./pages/Proposal/View'))
const ContractsGenerateLink = LazyLoading(() => import('./pages/Contracts/ContractGenerateSign'))
const PortabilityGenerateSign = LazyLoading(() =>
  import('./pages/Contracts/PortabilityGenerateSign')
)
const PortabilitiesList = LazyLoading(() => import('./pages/Proposal/List/Portabilities'))
const PendingSignContract = LazyLoading(() => import('./pages/Contracts/List/PendingSign'))
const PendingTedList = LazyLoading(() => import('./pages/Contracts/List/PendingTed'))
const DeniedProposalList = LazyLoading(() => import('./pages/Proposal/List/DeniedProposal'))
const ProposalsListPortabilities = LazyLoading(() => import('./pages/Proposal/List/Portabilities'))
const Renegotiate = LazyLoading(() => import('./pages/Renegotiate'))
const RenegotiateList = LazyLoading(() => import('./pages/Renegotiate/List'))
const RenegotiateSimulate = LazyLoading(() => import('./pages/Renegotiate/Simulate'))
const RenegotiateResume = LazyLoading(() => import('./pages/Renegotiate/Resume'))
const Portability = LazyLoading(() => import('./pages/Portability'))
const PortabilityList = LazyLoading(() => import('./pages/Portability/List'))
const PortabilitySimulate = LazyLoading(() => import('./pages/Portability/Simulate'))
const UpdateDataForm = LazyLoading(() => import('./pages/UpdateData/Form'))
const MyAccount = LazyLoading(() => import('app/Company/pages/MyAccount'))
const MyAccountList = LazyLoading(() => import('app/Company/pages/MyAccount/List'))
const MyAccountChangeMail = LazyLoading(() => import('app/Company/pages/MyAccount/ChangeMail'))
const MyAccountChangePassword = LazyLoading(() =>
  import('app/Company/pages/MyAccount/ChangePassword')
)
const MyAccountChangePhone = LazyLoading(() => import('app/Company/pages/MyAccount/ChangePhone'))
const MyAccountToken = LazyLoading(() => import('app/Company/pages/MyAccount/Token'))

const HireCreditVerifyIdentity = LazyLoading(() => import('./pages/HireCredit/VerifyIdentity'))
const HireCreditoUpdateDataForm = LazyLoading(() => import('./pages/HireCredit/Register'))

export const DefaultRoutes: { [key: string]: IRoute } = {
  DEFAULT: {
    path: routeCodes.NULL_ROUTE,
    name: 'Página não encontrada',
    component: Redirect,
    hideInSidePanel: true,
    defaultRoute: true,
  },
  HIRE_CREDIT: {
    path: routeCodes.CORBAN.HIRE_CREDIT,
    name: 'Contratar Crédito',
    icon: AttachMoney,
    permissions: [hasSubmodulePermission(CORBAN_SUBMODULES.CEP)],
    component: HireCredit,
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Verificar identidade',
        component: HireCreditVerifyIdentity,
        componentProps: {
          redirectPath: `${routeCodes.ENTITY.CORBAN}${routeCodes.CORBAN.HIRE_CREDIT}${subRouteCodes.CORBAN.HIRE_CREDIT.VERIFY_TOKEN}`,
        },
      },
      VERIFY_TOKEN: {
        path: subRouteCodes.CORBAN.HIRE_CREDIT.VERIFY_TOKEN,
        name: 'Verificar código',
        component: VerifyToken,
      },
      HIRE_CREDIT: {
        path: subRouteCodes.CORBAN.HIRE_CREDIT.SIMULATE,
        name: 'Simulação de crédito',
        component: Simulate,
      },
      CONFIRM_DATA: {
        path: subRouteCodes.CORBAN.HIRE_CREDIT.CONFIRM_DATA,
        name: 'Atualizar dados',
        component: HireCreditoUpdateDataForm,
      },
      RESUME: {
        path: subRouteCodes.CORBAN.HIRE_CREDIT.RESUME,
        name: 'Resumo',
        component: Resume,
      },
    },
  },
  ASSOCIATE_CHANNEL: {
    path: routeCodes.CORBAN.ASSOCIATE_CHANNELS,
    name: 'Associar sub-corbans',
    icon: PlaylistAddOutlined,
    permissions: [hasSubmodulePermission(CORBAN_SUBMODULES.CONFIG)],
    component: AssociateChannels,
  },
  OPERATORS: {
    path: routeCodes.CORBAN.OPERATORS,
    name: 'Operadores',
    icon: PermIdentityOutlined,
    permissions: [hasSubmodulePermission(CORBAN_SUBMODULES.CONFIG)],
    component: OperatorsLayout,
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Operadores',
        component: OperatorsList,
      },
      VIEW: {
        path: '/:id/:cnpj',
        name: 'Visualizar operador',
        component: OperatorsView,
      },
      EDIT: {
        path: `${subRouteCodes.EDIT}/:id/:cnpj`,
        name: 'Editar Operador',
        component: OperatorsCreate,
      },
      NEW: {
        path: subRouteCodes.NEW,
        name: 'Criar Operador',
        component: OperatorsCreate,
      },
    },
  },
  RENEGOTIATE: {
    path: routeCodes.CORBAN.RENEGOTIATE,
    name: 'Renegociação',
    icon: RestorePageOutlined,
    permissions: [hasSubmodulePermission(CORBAN_SUBMODULES.CEP)],
    component: Renegotiate,
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Renegociação',
        component: VerifyIdentity,
        componentProps: {
          redirectPath: `${routeCodes.ENTITY.CORBAN}${routeCodes.CORBAN.RENEGOTIATE}${subRouteCodes.CORBAN.RENEGOTIATE.VERIFY_TOKEN}`,
        },
      },
      VERIFY_TOKEN: {
        path: subRouteCodes.CORBAN.HIRE_CREDIT.VERIFY_TOKEN,
        name: 'Verificar código',
        component: VerifyToken,
        componentProps: {
          redirectPath: `${routeCodes.ENTITY.CORBAN}${routeCodes.CORBAN.RENEGOTIATE}${subRouteCodes.LIST}`,
        },
      },
      CONTRACTS: {
        path: subRouteCodes.LIST,
        name: 'Renegociação',
        component: RenegotiateList,
      },
      SIMULATE: {
        path: subRouteCodes.CORBAN.RENEGOTIATE.SIMULATE,
        name: 'Renegociação',
        component: RenegotiateSimulate,
      },
      CONFIRM_DATA: {
        path: subRouteCodes.CORBAN.RENEGOTIATE.CONFIRM_DATA,
        name: 'Atualizar dados',
        component: UpdateDataForm,
      },
      RESUME: {
        path: subRouteCodes.CORBAN.RENEGOTIATE.RESUME,
        name: 'Resumo',
        component: RenegotiateResume,
      },
    },
  },
  MY_ACCOUNT: {
    path: routeCodes.COMPANY.MY_ACCOUNT,
    name: 'Minha Conta',
    component: MyAccount,
    hideInSidePanel: true,
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Minha Conta',
        component: MyAccountList,
      },
      CHANGE_MAIL: {
        path: subRouteCodes.COMPANY.MY_ACCOUNT.CHANGE_MAIL,
        name: 'Alterar email',
        component: MyAccountChangeMail,
      },
      CHANGE_PASSWORD: {
        path: subRouteCodes.COMPANY.MY_ACCOUNT.CHANGE_PASSWORD,
        name: 'Alterar Senha',
        component: MyAccountChangePassword,
      },
      CHANGE_TELEPHONE: {
        path: subRouteCodes.COMPANY.MY_ACCOUNT.CHANGE_PHONE,
        name: 'Alterar Telefone',
        component: MyAccountChangePhone,
      },
      TOKEN: {
        path: subRouteCodes.COMPANY.MY_ACCOUNT.TOKEN,
        name: 'Confirmar código',
        component: MyAccountToken,
      },
    },
  },
  PORTABILITY: {
    path: routeCodes.CORBAN.PORTABILITY,
    name: 'Portabilidade',
    icon: SyncAlt,
    component: Portability,
    permissions: [hasSubmodulePermission(CORBAN_SUBMODULES.CEP)],
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Portabilidade',
        component: PortabilityList,
      },
      VERIFY_TOKEN: {
        path: subRouteCodes.CORBAN.HIRE_CREDIT.VERIFY_TOKEN,
        name: 'Verificar código',
        component: VerifyToken,
        componentProps: {
          redirectPath: `${routeCodes.ENTITY.CORBAN}${routeCodes.CORBAN.PORTABILITY}${subRouteCodes.CORBAN.PORTABILITY.SIMULATE}`,
        },
      },
      VERIFY_IDENTITY: {
        path: subRouteCodes.CORBAN.VERIFY_IDENTITY,
        name: 'Portabilidade',
        component: VerifyIdentity,
        componentProps: {
          redirectPath: `${routeCodes.ENTITY.CORBAN}${routeCodes.CORBAN.PORTABILITY}${subRouteCodes.CORBAN.PORTABILITY.VERIFY_TOKEN}`,
        },
      },
      SIMULATE: {
        path: subRouteCodes.EMPLOYEE.PORTABILITY.SIMULATE,
        name: 'Simular',
        component: PortabilitySimulate,
      },
      PORTABILITY_GENERATE_LINK: {
        path: `${subRouteCodes.CORBAN.CONTRACTS.GENERATE_SIGN_LINK}/:id`,
        name: 'Gerar link',
        component: PortabilityGenerateSign,
      },
    },
  },
  PROPOSALS: {
    path: routeCodes.CORBAN.PROPOSALS,
    name: 'Propostas',
    component: ProposalsLayout,
    icon: Description,
    permissions: [hasSubmodulePermission(CORBAN_SUBMODULES.CEP)],
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Proposta',
        component: ProposalsList,
      },
      VIEW: {
        path: '/:id',
        name: 'Visualizar contrato',
        component: ProposalsView,
      },
      ALL: {
        path: subRouteCodes.CORBAN.PROPOSALS.ALL,
        name: 'Proposta',
        component: ProposalsList,
      },
      CREDIT_DESK: {
        path: subRouteCodes.CORBAN.PROPOSALS.CREDIT_DESK,
        name: 'Proposta',
        component: ProposalsList,
      },
      GENERATE_LINK: {
        path: `${subRouteCodes.CORBAN.PROPOSALS.GENERATE_SIGN_LINK}/:id`,
        name: 'Gerar link',
        component: ContractsGenerateLink,
      },
      EXPIRATED: {
        path: subRouteCodes.CORBAN.PROPOSALS.EXPIRATED,
        name: 'Proposta',
        component: ProposalsList,
      },
      PENDING_SIGN: {
        path: subRouteCodes.CORBAN.PROPOSALS.PENDING_SIGN,
        name: 'Proposta',
        component: ProposalsList,
      },
      DENIED_PROPOSAL: {
        path: subRouteCodes.CORBAN.PROPOSALS.DENIED_PROPOSAL,
        name: 'Propostas Negadas',
        component: DeniedProposalList,
      },
      PORTABILITIES: {
        path: subRouteCodes.CORBAN.PROPOSALS.PORTABILITIES,
        name: 'Portabilidades',
        component: PortabilitiesList,
      },
      PORTABILITIES_PENDING_SIGN: {
        path: subRouteCodes.CORBAN.PROPOSALS.PORTABILITIES_PENDING_SIGN,
        name: 'Portabilidades pendentes de assinatura',
        component: ProposalsListPortabilities,
      },
    },
  },
  CONTRACTS: {
    path: routeCodes.CORBAN.CONTRACT,
    name: 'Contratos',
    component: ContractsLayout,
    icon: Receipt,
    permissions: [hasSubmodulePermission(CORBAN_SUBMODULES.CEP)],
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Contratos',
        component: ContractsList,
      },
      ALL: {
        path: subRouteCodes.CORBAN.CONTRACTS.ALL,
        name: 'Contratos',
        component: ContractsList,
      },
      VIEW: {
        path: '/:id',
        name: 'Visualizar contrato',
        component: ContractsView,
      },
      ACTIVE: {
        path: '/active',
        name: 'Contratos',
        component: ContractsList,
      },
      GENERATE_LINK: {
        path: `${subRouteCodes.CORBAN.CONTRACTS.GENERATE_SIGN_LINK}/:id`,
        name: 'Gerar link',
        component: ContractsGenerateLink,
      },
      CANCELATED: {
        path: subRouteCodes.CORBAN.CONTRACTS.CANCELATED,
        name: 'Contratos',
        component: ContractsList,
      },
      PENDING_SIGN: {
        path: subRouteCodes.CORBAN.CONTRACTS.PENDING_SIGN,
        name: 'Contratos',
        component: PendingSignContract,
      },
      DENIED_AVERBATION: {
        path: subRouteCodes.CORBAN.CONTRACTS.DENIED_AVERBATION,
        name: 'Contratos',
        component: ContractsList,
      },
      FINISHED: {
        path: subRouteCodes.CORBAN.CONTRACTS.FINISHED,
        name: 'Contratos',
        component: ContractsList,
      },
      PENDING_AVERBATION: {
        path: subRouteCodes.CORBAN.CONTRACTS.PENDING_AVERBATION,
        name: 'Contratos',
        component: ContractsList,
      },
      REVERSED: {
        path: subRouteCodes.CORBAN.CONTRACTS.REVERSED,
        name: 'Contratos',
        component: ContractsList,
      },
      PENDING_TED: {
        path: subRouteCodes.CORBAN.CONTRACTS.PENDING_TED,
        name: 'Pendentes de TED',
        component: PendingTedList,
      },
    },
  },
  LGPD: {
    path: routeCodes.CORBAN.LGPD,
    name: 'Cadastro de funcionário',
    component: LGPD,
    permissions: [hasSubmodulePermission(CORBAN_SUBMODULES.CEP)],
    icon: SupervisorAccount,
    routes: {
      COMPANY_SELECT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Selecionar empresas',
        component: LGPDCompanySelect,
      },
      FORM: {
        path: subRouteCodes.NEW,
        name: 'Criar Funcionário',
        component: LGPDForm,
      },
    },
  },
}

export default DefaultRoutes
