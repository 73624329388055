import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import { routeCodes } from 'constants/routes'

interface Props {
  redirectLogin?: boolean
}

export const GoBack: React.FC<Props> = ({ redirectLogin }) => {
  const history = useHistory()

  return (
    <Box
      display='flex'
      alignItems='center'
      mb={1}
      onClick={() => (redirectLogin ? history.push(routeCodes.NULL_ROUTE) : history.goBack())}
    >
      <ArrowBack fontSize='small' fontVariant='subtitle2' className='cursor-pointer' />
      <Typography variant='subtitle2' className='cursor-pointer'>
        VOLTAR
      </Typography>
    </Box>
  )
}

export default GoBack
