import { IFipe } from 'interfaces/fipe'
import { orderBy } from 'lodash'
export const ufs = [
  {
    value: 'AC',
    label: 'AC',
  },
  {
    value: 'AL',
    label: 'AL',
  },
  {
    value: 'AP',
    label: 'AP',
  },
  {
    value: 'AM',
    label: 'AM',
  },
  {
    value: 'BA',
    label: 'BA',
  },
  {
    value: 'CE',
    label: 'CE',
  },
  {
    value: 'DF',
    label: 'DF',
  },
  {
    value: 'ES',
    label: 'ES',
  },
  {
    value: 'GO',
    label: 'GO',
  },
  {
    value: 'MA',
    label: 'MA',
  },
  {
    value: 'MT',
    label: 'MT',
  },
  {
    value: 'MS',
    label: 'MS',
  },
  {
    value: 'MG',
    label: 'MG',
  },
  {
    value: 'PA',
    label: 'PA',
  },
  {
    value: 'PB',
    label: 'PB',
  },
  {
    value: 'PR',
    label: 'PR',
  },
  {
    value: 'PE',
    label: 'PE',
  },
  {
    value: 'PI',
    label: 'PI',
  },
  {
    value: 'RJ',
    label: 'RJ',
  },
  {
    value: 'RN',
    label: 'RN',
  },
  {
    value: 'RS',
    label: 'RS',
  },
  {
    value: 'RO',
    label: 'RO',
  },
  {
    value: 'RR',
    label: 'RR',
  },
  {
    value: 'SC',
    label: 'SC',
  },
  {
    value: 'SP',
    label: 'SP',
  },
  {
    value: 'SE',
    label: 'SE',
  },
  {
    value: 'TO',
    label: 'TO',
  },
]

export const civilState = [
  {
    value: 'casado',
    label: 'Casado(a)',
  },
  {
    value: 'solteiro',
    label: 'Solteiro(a)',
  },
  {
    value: 'divorciado',
    label: 'Divorciado(a)',
  },
  {
    value: 'viuvo',
    label: 'Viúvo(a)',
  },
  {
    value: 'uniao-estavel',
    label: 'União Estável',
  },
]

export const documentTypes = [
  {
    value: 'RG',
    label: 'RG',
  },
  {
    value: 'CNH',
    label: 'CNH',
  },
  {
    value: 'RNE',
    label: 'RNE',
  },
  {
    value: 'PASSAPORTE',
    label: 'PASSAPORTE',
  },
]

export const banks = [
  ...orderBy(
    [
      {
        value: '001',
        label: '001 - Banco Do Brasil S.A.',
      },
      {
        value: '237',
        label: '237 - Banco Bradesco S.A.',
      },
      {
        value: '341',
        label: '341 - Itau Unibanco S.A.',
      },
      {
        value: '033',
        label: '033 - Banco Santander (brasil) S.A.',
      },
      {
        value: '077',
        label: '077 - Banco Intermedium S.A.',
      },
      {
        value: '104',
        label: '104 - Caixa Economica Federal',
      },
      {
        value: '212',
        label: '212 - Banco Original S.A.',
      },
      {
        value: '260',
        label: '260 - Nu Pagamentos S.A.',
      },
      {
        value: '336',
        label: '336 - C6 Bank S.A',
      },
      {
        value: '745',
        label: '745 - Banco Citibank S.A.',
      },
      {
        value: '418',
        label: '418 - ZIPDIN Soluções Digitais S/A',
      },
      {
        value: '426',
        label: '426 - Biorc Financeira - CFI S.A',
      },
      {
        value: '335',
        label: '335 - Banco Digio S.A',
      },
      {
        value: '422',
        label: '422 - Banco Safra S.A.',
      },
      {
        value: '041',
        label: '041 - Banco Do Estado Do Rio Grande Do Sul S.A',
      },
      {
        value: '070',
        label: '070 - Brb - Banco De Brasilia S.A.',
      },
      {
        value: '735',
        label: '735 - Banco Pottencial S.A.',
      },
      {
        value: '136',
        label: '136 - Cc Unicred Do Brasil',
      },
      {
        value: '741',
        label: '741 - Banco Ribeirao Preto S.A.',
      },
      {
        value: '087',
        label: '087 - Unicred Central Santa Catarina',
      },
      {
        value: '299',
        label: '299 - Sorocred - Crédito, Financiamento e Investimento S/A.',
      },
      {
        value: '739',
        label: '739 - Cetelem',
      },
      {
        value: '743',
        label: '743 - Banco Semear S.A.',
      },
      {
        value: '100',
        label: '100 - Sc Planner',
      },
      {
        value: '096',
        label: '096 - Banco Bmfbovespa',
      },
      {
        value: '747',
        label: '747 - Banco Rabobank International Brasil S.A.',
      },
      {
        value: '748',
        label: '748 - Banco Cooperativo Sicredi S.A.',
      },
      {
        value: '752',
        label: '752 - Banco Bnp Paribas Brasil S.A',
      },
      {
        value: '091',
        label: '091 - Unicred Central Rs - Central De Coop Eco',
      },
      {
        value: '108',
        label: '108 - Scfi Portocred',
      },
      {
        value: '756',
        label: '756 - Banco Cooperativo Do Brasil S.A.',
      },
      {
        value: '757',
        label: '757 - Keb Hana Do Brasil',
      },
      {
        value: '102',
        label: '102 - Sc Xp Investimentos',
      },
      {
        value: '084',
        label: '084 - Cc Uniprime Norte Do Parana',
      },
      {
        value: '066',
        label: '066 - Morgan Stanley',
      },
      {
        value: '143',
        label: '143 - Sc Treviso',
      },
      {
        value: '062',
        label: '062 - Hipercard Banco Multiplo S.A',
      },
      {
        value: '074',
        label: '074 - Banco J. Safra S.A.',
      },
      {
        value: '099',
        label: '099 - Uniprime Central - Central Int De Coop D',
      },
      {
        value: '025',
        label: '025 - Banco Alfa S/a',
      },
      {
        value: '075',
        label: '075 - Abn Amro',
      },
      {
        value: '040',
        label: '040 - Banco Cargill S.A',
      },
      {
        value: '063',
        label: '063 - Banco Bradescard S.A.',
      },
      {
        value: '112',
        label: '112 - Cc Brasil Central',
      },
      {
        value: '064',
        label: '064 - Goldman Sachs Do Brasil-Banco Multiplo S',
      },
      {
        value: '097',
        label: '097 - Cooperativa Central De Credito Noroeste',
      },
      {
        value: '016',
        label: '016 - Cc Creditran',
      },
      {
        value: '003',
        label: '003 - Banco Da Amazonia S.A.',
      },
      {
        value: '060',
        label: '060 - Confidence Corretora De Cambio',
      },
      {
        value: '037',
        label: '037 - Banco Do Estado Do Para S.A.',
      },
      {
        value: '085',
        label: '085 - Cooperativa Central De Credito Urbano -',
      },
      {
        value: '114',
        label: '114 - Cecoopes-Central Das Coop De Econ E Cred',
      },
      {
        value: '036',
        label: '036 - Banco Bradesco Bbi S.A',
      },
      {
        value: '394',
        label: '394 - Banco Bradesco Financiamentos S.A.',
      },
      {
        value: '004',
        label: '004 - Banco Do Nordeste Do Brasil S.A.',
      },
      {
        value: '320',
        label: '320 - Ccb Brasil',
      },
      {
        value: '321',
        label: '321 - Crefaz Sociedade de Crédito',
      },
      {
        value: '105',
        label: '105 - Scfi Lecca',
      },
      {
        value: '076',
        label: '076 - Kdb Do Brasil',
      },
      {
        value: '082',
        label: '082 - Topazio',
      },
      {
        value: '093',
        label: '093 - Scm Polocred',
      },
      {
        value: '157',
        label: '157 - Sc Icap',
      },
      {
        value: '014',
        label: '014 - Natixis Brasil S.A. - Banco Multiplo',
      },
      {
        value: '130',
        label: '130 - Scfi Caruana',
      },
      {
        value: '329',
        label: '329 - QI Sociedade de Crédito Direto S.A',
      },
      {
        value: '342',
        label: '342 - CREDITAS SCD',
      },
      {
        value: '019',
        label: '019 - Banco Azteca Do Brasil Sa',
      },
      {
        value: '127',
        label: '127 - Sc Codepe',
      },
      {
        value: '079',
        label: '079 - Banco Original Do Agronegocio S.A.',
      },
      {
        value: '081',
        label: '081 - Bbn Banco Brasileiro De Negocios S.A',
      },
      {
        value: '083',
        label: '083 - Banco Da China Brasil S.A.',
      },
      {
        value: '138',
        label: '138 - Sc Get Money',
      },
      {
        value: '024',
        label: '024 - Banco De Pernambuco S.A.-Bandepe',
      },
      {
        value: '088',
        label: '088 - Bm Randon',
      },
      {
        value: '095',
        label: '095 - Banco Confidence De Cambio Sa',
      },
      {
        value: '118',
        label: '118 - Bi Standard Chartered',
      },
      {
        value: '137',
        label: '137 - Sc Multimoney',
      },
      {
        value: '047',
        label: '047 - Banco Do Estado De Sergipe S.A.',
      },
      {
        value: '126',
        label: '126 - Bi Br Partners',
      },
      {
        value: '147',
        label: '147 - Sc Rico',
      },
      {
        value: '123',
        label: '123 - Scfi Agiplan',
      },
      {
        value: '119',
        label: '119 - Western Union',
      },
      {
        value: '254',
        label: '254 - Parana Banco S.A.',
      },
      {
        value: '107',
        label: '107 - Banco Bbm S.A',
      },
      {
        value: '412',
        label: '412 - Banco Capital S.A.',
      },
      {
        value: '124',
        label: '124 - Banco Woori Bank Do Brasil S.A',
      },
      {
        value: '149',
        label: '149 - Scfi Facta Financeira',
      },
      {
        value: '142',
        label: '142 - Sc Broker Brasil',
      },
      {
        value: '389',
        label: '389 - Banco Mercantil Do Brasil S.A.',
      },
      {
        value: '184',
        label: '184 - Itau Bba',
      },
      {
        value: '634',
        label: '634 - Banco Triangulo S.A.',
      },
      {
        value: '013',
        label: '013 - Sc Senso',
      },
      {
        value: '132',
        label: '132 - Icbc Do Brasil Banco Multiplo S.A.',
      },
      {
        value: '129',
        label: '129 - Ubs Brasil Banco De Investimento S.A.',
      },
      {
        value: '128',
        label: '128 - Bcam Ms Bank',
      },
      {
        value: '146',
        label: '146 - Sc Guitta',
      },
      {
        value: '021',
        label: '021 - Banestes S.A Banco Do Estado Do Espirito',
      },
      {
        value: '246',
        label: '246 - Banco Abc Brasil S.A.',
      },
      {
        value: '751',
        label: '751 - Scotiabank Brasil S.A Banco Multiplo',
      },
      {
        value: '208',
        label: '208 - Banco Btg Pactual S.A.',
      },
      {
        value: '746',
        label: '746 - Banco Modal S.A.',
      },
      {
        value: '274',
        label:
          '274 - Money Plus Sociedade de Crédito ao Microempreendedor e a Empresa de Pequeno Porte',
      },
      {
        value: '241',
        label: '241 - Banco Classico S.A.',
      },
      {
        value: '612',
        label: '612 - Banco Guanabara S.A.',
      },
      {
        value: '604',
        label: '604 - Banco Industrial Do Brasil S. A.',
      },
      {
        value: '505',
        label: '505 - Banco Credit Suisse (brasil) S.A.',
      },
      {
        value: '300',
        label: '300 - Banco De La Nacion Argentina',
      },
      {
        value: '477',
        label: '477 - Citibank N.A.',
      },
      {
        value: '266',
        label: '266 - Banco Cedula S.A.',
      },
      {
        value: '122',
        label: '122 - Bradesco Berj',
      },
      {
        value: '376',
        label: '376 - Banco J.P. Morgan S.A.',
      },
      {
        value: '263',
        label: '263 - Banco Cacique S.A.',
      },
      {
        value: '473',
        label: '473 - Banco Caixa Geral - Brasil S.A.',
      },
      {
        value: '248',
        label: '248 - Banco Boa Vista Interatlantico S.A',
      },
      {
        value: '120',
        label: '120 - Banco Rodobens S.A',
      },
      {
        value: '265',
        label: '265 - Banco Fator S.A.',
      },
      {
        value: '134',
        label: '134 - Dtvm Bgc Liquidez',
      },
      {
        value: '641',
        label: '641 - Banco Alvorada S.A.',
      },
      {
        value: '719',
        label: '719 - Banif-Banco Internacional Do Funchal (br',
      },
      {
        value: '029',
        label: '029 - Itau Bmg Consignado',
      },
      {
        value: '243',
        label: '243 - Banco Maxima S.A.',
      },
      {
        value: '078',
        label: '078 - Bi Haitong Do Brasil',
      },
      {
        value: '111',
        label: '111 - Dtvm Oliveira Trust',
      },
      {
        value: '017',
        label: '017 - Bny Mellon S.A.',
      },
      {
        value: '151',
        label: '151 - Banco Nossa Caixa S.A',
      },
      {
        value: '495',
        label: '495 - Banco De La Provincia De Buenos Aires',
      },
      {
        value: '125',
        label: '125 - Brasil Plural S.A. Banco Multiplo',
      },
      {
        value: '488',
        label: '488 - Jpmorgan Chase Bank',
      },
      {
        value: '065',
        label: '065 - Andbank',
      },
      {
        value: '492',
        label: '492 - Ing Bank',
      },
      {
        value: '145',
        label: '145 - Sc Levycam',
      },
      {
        value: '250',
        label: '250 - Bcv - Banco De Credito E Varejo S.A',
      },
      {
        value: '494',
        label: '494 - Banco De La Republica Oriental Del Urugu',
      },
      {
        value: '213',
        label: '213 - Banco Arbi S.A.',
      },
      {
        value: '139',
        label: '139 - Intesa Sanpaolo',
      },
      {
        value: '018',
        label: '018 - Bm Tricury',
      },
      {
        value: '630',
        label: '630 - Intercap',
      },
      {
        value: '224',
        label: '224 - Banco Fibra S.A.',
      },
      {
        value: '600',
        label: '600 - Banco Luso Brasileiro S.A.',
      },
      {
        value: '623',
        label: '623 - Pan',
      },
      {
        value: '204',
        label: '204 - Banco Bradesco Cartoes S.A.',
      },
      {
        value: '655',
        label: '655 - Banco Votorantim S.A.',
      },
      {
        value: '479',
        label: '479 - Banco Itaubank S.A.',
      },
      {
        value: '456',
        label: '456 - Banco De Tokyo Mitsubishi Ufj Brasil S.A',
      },
      {
        value: '464',
        label: '464 - Banco Sumitomo Mitsui Brasileiro S.A.',
      },
      {
        value: '652',
        label: '652 - Banco Itau Holding Financeira S.A',
      },
      {
        value: '637',
        label: '637 - Banco Sofisa S.A.',
      },
      {
        value: '653',
        label: '653 - Banco Indusval S.A.',
      },
      {
        value: '230',
        label: '230 - Unicard Banco Multiplo S.A',
      },
      {
        value: '370',
        label: '370 - Mizuho',
      },
      {
        value: '740',
        label: '740 - Banco Barclays S.A.',
      },
      {
        value: '249',
        label: '249 - Banco Investcred Unibanco S.A',
      },
      {
        value: '318',
        label: '318 - Banco Bmg S.A.',
      },
      {
        value: '214',
        label: '214 - Banco Dibens S.A.',
      },
      {
        value: '626',
        label: '626 - Banco Ficsa S.A.',
      },
      {
        value: '366',
        label: '366 - Banco Societe Generale Brasil S.A',
      },
      {
        value: '113',
        label: '113 - Sc Magliano',
      },
      {
        value: '131',
        label: '131 - Tullett Prebon Brasil Corretora de Valores e Câmbio Ltda.',
      },
      {
        value: '011',
        label: '011 - Credit Suisse Hedging-Griffo Corretora de Valores S.A',
      },
      {
        value: '611',
        label: '611 - Banco Paulista S.A.',
      },
      {
        value: '755',
        label: '755 - Bank Of America Merrill Lynch Banco Mult',
      },
      {
        value: '089',
        label: '089 - Cooperativa de Crédito Rural da Região da Mogiana',
      },
      {
        value: '643',
        label: '643 - Banco Pine S.A.',
      },
      {
        value: '140',
        label: '140 - Sc Easynvest',
      },
      {
        value: '707',
        label: '707 - Banco Daycoval S.A.',
      },
      {
        value: '101',
        label: '101 - Dtvm Renascenca',
      },
      {
        value: '487',
        label: '487 - Deutsche Bank S. A. - Banco Alemao',
      },
      {
        value: '233',
        label: '233 - Banco Cifra S.A.',
      },
      {
        value: '167',
        label: '167 - Sc S. Hayata',
      },
      {
        value: '633',
        label: '633 - Banco Rendimento S.A.',
      },
      {
        value: '409',
        label: '409 - Unibanco - União de Bancos Brasileiros S.A.',
      },
      {
        value: '218',
        label: '218 - Banco BS2 S.A.',
      },
      {
        value: '090',
        label: '090 - Cooperativa Central De Credito Do Estado',
      },
      {
        value: '080',
        label: '080 - BT Corretora de Câmbio Ltda.',
      },
      {
        value: '753',
        label: '753 - Nbc Bank Brasil S.A.- Banco Multiplo',
      },
      {
        value: '222',
        label: '222 - Banco Credit Agricole Brasil S.A.',
      },
      {
        value: '754',
        label: '754 - Banco Sistema',
      },
      {
        value: '098',
        label: '098 - Credialianca Cooperativa De Credito Rura',
      },
      {
        value: '610',
        label: '610 - Banco Vr S.A.',
      },
      {
        value: '712',
        label: '712 - Banco Ourinvest S.A.',
      },
      {
        value: '010',
        label: '010 - Cc Credicoamo Credito Rural Cooperativa',
      },
      {
        value: '217',
        label: '217 - Banco John Deere S.A.',
      },
      {
        value: '117',
        label: '117 - Advanced Corretora de Câmbio Ltda.',
      },
      {
        value: '654',
        label: '654 - Banco A.J. Renner S.A.',
      },
      {
        value: '180',
        label: '180 - CM Capital Markets Corretora de Câmbio, Títulos e Valores Mobiliários Ltda.',
      },
      {
        value: '190',
        label: '190 - Servicoop',
      },
      {
        value: '191',
        label: '191 - Nova Futura Corretora de Títulos e Valores Mobiliários Ltda.',
      },
      {
        value: '012',
        label: '012 - Banco Inbursa S.A.',
      },
      {
        value: '159',
        label: '159 - Casa do Crédito S.A.Sociedade de Crédito ao Microempreendedor',
      },
      {
        value: '172',
        label: '172 - Albatross Corretora de Câmbio e Valores S.A',
      },
      {
        value: '189',
        label: '189 - HS Financeira S / A Crédito, Financiamento e Investimentos',
      },
      {
        value: '273',
        label: '273 - Cooperativa de Crédito Rural de São Miguel do Oeste - Sulcredi / São Miguel',
      },
      {
        value: '183',
        label: '183 - Socred S.A.- Sociedade de Crédito ao Microempreendedor',
      },
      {
        value: '133',
        label: '133 - Conf. Nacional das Cooperativas Centrais de Crédito e Economia Familiar',
      },
      {
        value: '276',
        label: '276 - Senff S.A.- Crédito, Financiamento e Investimento',
      },
      {
        value: '144',
        label: '144 - Bexs Banco de Câmbio S.A.',
      },
      {
        value: '173',
        label: '173 - BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.',
      },
      {
        value: '268',
        label: '268 - Barigui Companhia Hipotecária',
      },
      {
        value: '197',
        label: '197 - Stone Pagamentos S.A.',
      },
      {
        value: '545',
        label: '545 - Senso Corretora de Câmbio e Valores Mobiliários S.A.',
      },
      {
        value: '298',
        label: '298 - Vips Corretora de Câmbio Ltda.',
      },
      {
        value: '194',
        label: '194 - Parmetal Distribuidora de Títulos e Valores Mobiliários Ltda.',
      },
      {
        value: '163',
        label: '163 - Commerzbank Brasil S.A.- Banco Múltiplo',
      },
      {
        value: '280',
        label: '280 - Avista S.A.Crédito, Financiamento e Investimento',
      },
      {
        value: '279',
        label: '279 - Cooperativa de Crédito Rural de Primavera do Leste',
      },
      {
        value: '182',
        label: '182 - Dacasa Financeira S / A - Sociedade de Crédito, Financiamento e Investimento',
      },
      {
        value: '278',
        label: '278 - Genial Investimentos Corretora de Valores Mobiliários S.A.',
      },
      {
        value: '271',
        label: '271 - IB Corretora de Câmbio, Títulos e Valores Mobiliários Ltda.',
      },
      {
        value: '196',
        label: '196 - Fair Corretora de Câmbio S.A.',
      },
      {
        value: '188',
        label: '188 - Ativa Investimentos S.A.Corretora de Títulos Câmbio e Valores',
      },
      {
        value: '174',
        label: '174 - Pernambucanas Financiadora S.A.Crédito, Financiamento e Investimento',
      },
      {
        value: '253',
        label: '253 - Bexs Corretora de Câmbio S / A',
      },
      {
        value: '269',
        label: '269 - HSBC Brasil S.A.Banco de Investimento',
      },
      {
        value: '270',
        label: '270 - Sagitur Corretora de Câmbio Ltda.',
      },
      {
        value: '288',
        label: '288 - Carol Distribuidora de Títulos e Valores Mobiliários Ltda.',
      },
      {
        value: '177',
        label: '177 - Guide Investimentos S.A.Corretora de Valores',
      },
      {
        value: '169',
        label: '169 - Banco Olé Bonsucesso Consignado S.A.',
      },
      {
        value: '283',
        label:
          '283 - RB Capital Investimentos Distribuidora de Títulos e Valores Mobiliários Ltda.',
      },
      {
        value: '272',
        label: '272 - AGK Corretora de Câmbio S.A',
      },
      {
        value: '399',
        label: '399 - Kirton Bank S.A - Banco Múltiplo',
      },
      {
        value: '015',
        label: '015 - UBS Brasil Corretora de Câmbio, Títulos e Valores Mobiliários S.A.',
      },
      {
        value: '307',
        label: '307 - Terra Investimentos Distribuidora de Títulos e Valores Mobiliários Ltda.',
      },
      {
        value: '286',
        label: '286 - Cooperativa de Crédito Rural de Ouro Sulcredi/Ouro',
      },
      {
        value: '290',
        label: '290 - Pagseguro Internet S.A.',
      },
      {
        value: '121',
        label: '121 - Banco Agibank S.A',
      },
      {
        value: '094',
        label: '094 - Banco Finaxis S.A.',
      },
      {
        value: '092',
        label: '092 - BRK S.A. Crédito, Financiamento e Investimento',
      },
      {
        value: '301',
        label: '301 - BPP Instituição de Pagamento S.A.',
      },
      {
        value: '309',
        label: '309 - Cambionet Corretora de Câmbio Ltda.',
      },
      {
        value: '310',
        label: '310 - Vortx Distribuidora de Títulos e Valores Mobiliários Ltda.',
      },
      {
        value: '292',
        label: '292 - BS2 Distribuidora de Títulos e Valores Mobiliários S.A.',
      },
      {
        value: '613',
        label: '613 - Omni Banco S.A',
      },
      {
        value: '069',
        label: '069 - Banco Crefisa S.A',
      },
      {
        value: '293',
        label: '293 - Lastro RDV Distribuidora de Títulos e Valores Mobiliários Ltda.',
      },
      {
        value: '285',
        label: '285 - Frente Corretora de Câmbio Ltda.',
      },
      {
        value: '281',
        label: '281 - Cooperativa de Crédito Rural Coopavel',
      },
      {
        value: '468',
        label: '468 - Portoseg S.A CFI',
      },
      {
        value: '403',
        label: '403 - Cora Sociedade de Crédito Direto S.A',
      },
      {
        value: '530',
        label: '530 - SER FINANCE SCD S.A',
      },
      {
        value: '566',
        label: '566 - FLAGSHIP IP LTDA',
      },
      {
        value: '377',
        label: '377 - BMS SCD S.A',
      },
    ],
    'value'
  ),
]

export const getBankByCode = (id: string) => {
  const selectedBank = banks.find((obj) => Number(obj.value) === Number(id))
  if (selectedBank) {
    return selectedBank.label
  }
  return ''
}

export const accountType = [
  {
    value: 'corrente',
    label: 'Conta Corrente',
  },
  {
    value: 'poupanca',
    label: 'Conta Poupança',
  },
]

export const accountTypeLegalPerson = [
  {
    value: 'corrente',
    label: 'Conta Corrente',
  },
]

export const accountTypeLegalPersonI = [
  {
    value: 'individual',
    label: 'Individual',
  },
  {
    value: 'conjunta',
    label: 'Conjunta',
  },
]

export const accountTypeDescriptions: { [key: string]: string } = {
  corrente: 'Conta corrente',
  poupanca: 'Poupança',
}

export const discountReasons = [
  {
    label: 'Sem divergência',
    value: 'sem-divergencia',
  },
  {
    label: 'Pensão alimenticia',
    value: 'pensao-alimenticia',
  },
  {
    label: 'Férias',
    value: 'ferias',
  },
  {
    label: 'Demissão',
    value: 'demissao',
  },
  {
    label: 'Afastado',
    value: 'afastado',
  },
  {
    label: 'Invalidez',
    value: 'invalidez',
  },
  {
    label: 'Óbito',
    value: 'obito',
  },
  {
    label: 'Fraude',
    value: 'fraude',
  },
  {
    label: 'Outros',
    value: 'outros',
  },
]

export const genre = [
  { label: 'Masculino', value: 'M' },
  { label: 'Feminino', value: 'F' },
]

export const genreDropdown = [
  { text: 'Masculino', value: 'M' },
  { text: 'Feminino', value: 'F' },
]

export const ppe = [
  { label: 'Sim', value: true },
  { label: 'Não', value: false },
]

export const vehicleCategory = [
  { label: 'Carros e utilitários', value: 'carros-e-utilitarios' },
  { label: 'Motos', value: 'motos' },
  { label: 'Caminhões e microônibus', value: 'caminhoes-e-microonibus' },
  { label: 'Máquinas', value: 'maquinas' },
]

export const parseVehicleCategory: { [key: string]: string } = {
  'carros-e-utilitarios': 'Carros e utilitários',
  motos: 'Motos',
  'caminhoes-e-microonibus': 'Caminhões e microônibus',
  maquinas: 'Máquinas',
  carro: 'Carro',
}

export const vehicleCondition = [
  { label: 'Novo', value: 'novo' },
  { label: 'Seminovo', value: 'seminovo' },
  { label: 'Usado', value: 'usado' },
]

export const initialFipeTable: IFipe = {
  marca: [],
  modelo: [],
  ano_modelo: [],
  tipo_veiculo: [],
  combustivel: [],
  valor_veiculo: [],
}

export const pixTypes = [
  { label: 'Não possui', value: '' },
  { label: 'CPF', value: 'cpf' },
  { label: 'CNPJ', value: 'cnpj' },
  { label: 'Telefone', value: 'telefone_celular' },
  { label: 'Email', value: 'email' },
  { label: 'Chave Aleatória', value: 'aleatoria' },
]
